import Config from '@/lib/config';
import { MainProvider } from '@/lib/context';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import * as React from 'react';
import './index.scss';
import { Main } from './main';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
  },
  typography: {
    fontFamily: Config.fontName,
  },
});

export const App: React.FC = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <MainProvider>
        <Main />
      </MainProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

export enum DataLevel {
  MAIN = 0,
  REGIONE = 1,
  PROVINCIA = 2,
  COMUNE = 3,
}

export enum Region {
  LOMBARDIA = 0,
  LAZIO = 1,
}

export enum Year {
  Y2022 = 1,
  Y2023 = 2,
}

export interface LabelValue<T> {
  label: string;
  value: T;
}

export interface ResultCodes {
  codReg: string;
  codCirc?: string;
  codProv: string;
  codClPlu?: string;
  codClUni?: string;
  codCom: string;
  secondBallot?: boolean;
}

export interface AutocompleteOption extends ResultCodes {
  nameComune: string;
  nameProvincia: string;
  nameRegione: string;
}

export interface Candidate {
  coalitionId: number;
  candidateId?: number;
  fullName: string | null;
  label: string;
  votes: string;
  percentage: number;
  fillColor: string;
}

export interface Coalition extends Candidate {
  candidates: Candidate[];
}

export interface MapLayer {
  fillColor: string;
  opacity: number;
  layerId: string;
  coordinates: number[];
}

export interface MapSettings {
  layerPropId: string;
  sourceLayer: string;
  zoom: number;
}

export interface Scrutinized {
  current: number;
  total: number;
}

export interface SummaryRow {
  label: string;
  value: string;
}

export interface Result {
  level: DataLevel;
  year: Year;
  region: Region;
  placeName: string;
  showVoti: boolean;
  scrutinized: Scrutinized;
  coalitions: Coalition[];
  idCodes: ResultCodes;
  summary: {
    blankCards: string;
    contestedCards: string;
    nullCards: string;
  };
}

export interface GradientRow {
  opacity: number;
  value: number;
}

export interface ResultAPI {
  int: {
    st: string;
    t_ele: string;
    f_elet: string;
    dt_ele: number;
    l_terr: string;
    area: string;
    cod_circ: string;
    desc_circ: string;
    cod_reg?: string;
    desc_reg?: string;
    cod_prov?: string;
    desc_prov?: string;
    cod_com?: string;
    desc_com?: string;
    cod_plu?: string;
    cod_cl_uni?: string;
    desc_cl_uni: string;
    ele_m: number;
    ele_f: number;
    ele_t: number;
    vot_m: number;
    vot_f: number;
    vot_t: number;
    perc_vot: string;
    sz_perv: number;
    sz_cons: number;
    sz_pres: number;
    sz_p_sind: number;
    sz_tot: number;
    coll_uni_perv: number;
    coll_uni_tot: number;
    tot_vot_uni: number;
    tot_vot_prop: number;
    fine_rip: string;
    sg_ass_uni: number;
    sg_ass_prop: number;
    sk_bianche: number;
    sk_nulle: number;
    sk_contestate: number;
    dt_agg: number;
    data_prec_elez: number;
    circ_sto: number;
  };
  isMultiCollegio?: boolean;
  note: null;
  note_din: [];
  cand: {
    cogn?: string;
    nome?: string;
    pos: number;
    voti: number;
    voti_uni?: number;
    voti_solo_can?: number;
    perc: string;
    perc_uni?: string;
    sg_ass_uni: number;
    sg_ass_prop: number;
    pos_contr?: number; // Valle d'Aosta e Trentino
    desc_contr?: string;
    liste: {
      pos: number;
      desc_lis: string;
      descr_lista: string;
      voti: number;
      perc: string;
      seggi: number;
    }[];
  }[];
}

export interface ResultAPIComuneTurnout {
  com: number;
  dt_com: number;
  enti_p: number;
  enti_t: number;
  perc: string;
  vot_m: number;
  vot_f: number;
  vot_t: number;
  perc_r: string;
}

export interface ResultAPIProvincia {
  enti: {
    ente_p: {
      desc: string;
      cod: number;
      ele_m: number;
      ele_f: number;
      ele_t: number;
    };
    enti_f: {
      tipo: string;
      desc: string;
      cod: number;
      ele_m: number;
      ele_f: number;
      ele_t: number;
      com_vot: ResultAPIComuneTurnout[];
    }[];
  };
}

export interface DynamicTurnout {
  com_att?: string;
  tipo: string;
  desc: string;
  cod: string;
  cod_prov: string;
  desc_prov: string;
  cod_reg: string;
  desc_reg: string;
  ele_m: number;
  ele_f: number;
  ele_t: number;
  com: {
    com_att: number;
    num_com: number;
  }[];
  com_vot: {
    com: string;
    dt_com: string;
    enti_p: number;
    enti_t: number;
    perc: string;
    vot_m: number;
    vot_f: number;
    vot_t: number;
    perc_r: string;
  }[];
}

import { useMainContext } from '@/lib/context';
import { getAutocompleteOptions } from '@/lib/getResults';
import { AutocompleteOption } from '@/lib/model';
import * as React from 'react';
import { MainComponent } from './MainComponent';

export const Main = () => {
  const { setComuneIsLoading, setSearchResult } = useMainContext();

  // Function used when pressing an option within searched results
  const onOptionPress = async (result: AutocompleteOption) => {
    setComuneIsLoading(true);

    // set search result and activate useEffect defined above
    setSearchResult(result);
  };

  const inputOptions = getAutocompleteOptions();

  return (
    <MainComponent inputOptions={inputOptions} onOptionPress={onOptionPress} />
  );
};

export const Clients = {
  SOLE: 'sole',
  AGI: 'agi',
  SKY: 'sky',
  GENERAL: 'general',
};

const Config = {
  projectName: process.env.REACT_APP_PROJECT_NAME || 'elections',
  headerTitle: process.env.REACT_APP_PROJECT_HEADER_TITLE || 'Elections',
  fontName: process.env.REACT_APP_FONT_NAME || 'Sky Text',
  client: process.env.REACT_APP_CLIENT || Clients.GENERAL,
  activeRegions: process.env.REACT_APP_ACTIVE_REGIONS || 'all',
  publicUrl: process.env.REACT_APP_PUBLIC_URL || '',
  isLiveActive: process.env.REACT_APP_IS_LIVE_ACTIVE === 'true',
  projectDataLocation:
    process.env.REACT_APP_PROJECT_DATA_LOC || '2023/comunali',
};

export default Config;

import {
  getDynamicTurnout,
  getLastVersion,
  getResults,
} from '@/lib/getResults';
import {
  AutocompleteOption,
  DynamicTurnout,
  Result,
  ResultCodes,
} from '@/lib/model';
import * as React from 'react';
import Config, { Clients } from './config';

export interface MainContextType {
  result: Result | null;
  setResult: (results: Result | null) => void;
  searchResult: ResultCodes | null;
  mainResult: Result | null;
  setMainResult: (result: Result) => void;
  setSearchResult: (value: AutocompleteOption | null) => void;
  isComuneLoading: boolean;
  setComuneIsLoading: (value: boolean) => void;
  dynamicTurnout: DynamicTurnout | null;
  setDynamicTurnout: (value: DynamicTurnout) => void;
  lastVersion: number;
}

export const INITIAL_STATE = {
  result: null,
  setResult: () => {},
  mainResult: {} as Result,
  setMainResult: () => {},
  searchResult: null,
  setSearchResult: () => {},
  isComuneLoading: false,
  setComuneIsLoading: () => {},
  toggleTurnout: () => {},
  dynamicTurnout: null,
  setDynamicTurnout: () => {},
  lastVersion: 1,
};

const MainContext = React.createContext<MainContextType>(INITIAL_STATE);

export const useMainContext = () => React.useContext(MainContext);

export const dispatchTrackingEvent = (eventDetail: any) => {
  if (Config.client !== Clients.SOLE) {
    return;
  }

  // dispatch tracking event with current details
  const event = new CustomEvent('electio:navigate', {
    detail: eventDetail,
  });

  document.dispatchEvent(event);
};

export const MainProvider: React.FC<Partial<React.PropsWithChildren>> = ({
  children,
}) => {
  const [result, setResult] = React.useState<Result | null>(
    INITIAL_STATE.result
  );
  const [searchResult, setSearchResult] =
    React.useState<AutocompleteOption | null>(INITIAL_STATE.searchResult);
  const [isComuneLoading, setComuneIsLoading] = React.useState(
    INITIAL_STATE.isComuneLoading
  );
  const [mainResult, setMainResult] = React.useState<Result | null>(null);
  const [dynamicTurnout, setDynamicTurnout] =
    React.useState<DynamicTurnout | null>(INITIAL_STATE.dynamicTurnout);
  const [lastVersion, setLastVersion] = React.useState(
    INITIAL_STATE.lastVersion
  );

  const timeOut = React.useRef<NodeJS.Timeout>();

  // Fetch last version
  React.useEffect(() => {
    const fn = async () => {
      clearTimeout(timeOut.current);

      const version = await getLastVersion();
      if (version !== lastVersion) {
        setLastVersion(version);
      }

      const MINUTE = 1000 * 60;
      timeOut.current = setTimeout(fn, MINUTE);
    };

    fn();

    return () => {
      clearTimeout(timeOut.current);
    };
  }, [lastVersion]);

  // Dynamic turnout
  React.useEffect(() => {
    const fn = async () => {
      setDynamicTurnout(null);

      const lastTurnout: DynamicTurnout = await getDynamicTurnout(
        result?.idCodes
      );

      setDynamicTurnout(lastTurnout);
    };

    fn();
  }, [lastVersion, result, setDynamicTurnout]);

  // Main result
  React.useEffect(() => {
    const fn = async () => {
      let mainResult = (await getResults(false))[0];

      if (!mainResult) {
        return;
      }

      setMainResult(mainResult);
    };

    fn();
    // we add last version so getResult refetches when it changes
  }, [setMainResult, lastVersion, dynamicTurnout]);

  // effect triggered after searching a result
  React.useEffect(() => {
    if (!searchResult) {
      return;
    }

    const fn = async () => {
      try {
        const comuni = await getResults(false, searchResult);
        const comune = comuni.find(
          c => c.idCodes.codCom === searchResult.codCom
        );

        if (!comune) {
          return;
        }

        setResult(comune);
        dispatchTrackingEvent({
          nameRegione: searchResult.nameRegione,
          nameProvincia: searchResult.nameProvincia,
          nameComune: searchResult.nameComune,
        });
      } catch (e) {
        console.log('e', e);
      } finally {
        setComuneIsLoading(false);
        setSearchResult(null);
      }
    };

    fn();
  }, [
    dynamicTurnout,
    isComuneLoading,
    searchResult,
    setResult,
    setComuneIsLoading,
  ]);

  return (
    <MainContext.Provider
      value={{
        result,
        setResult,
        mainResult,
        setMainResult,
        searchResult,
        setSearchResult,
        isComuneLoading,
        setComuneIsLoading,
        dynamicTurnout,
        setDynamicTurnout,
        lastVersion,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

import { Header } from '@/components/Header';
import Config from '@/lib/config';
import { useMainContext } from '@/lib/context';
import { AutocompleteOption } from '@/lib/model';
import * as React from 'react';
import './index.scss';
import { Results } from './Results';
import { SearchBar } from './SearchBar';

export interface MainComponentProps {
  onOptionPress: (value: AutocompleteOption) => void;
  inputOptions: AutocompleteOption[];
}

export const MainComponent: React.FC<MainComponentProps> = ({
  onOptionPress,
  inputOptions,
}) => {
  const { isComuneLoading } = useMainContext();

  return (
    <div className="Main">
      <Header title={Config.headerTitle} />
      <div className="Main__Content">
        <div>
          <div className="Main__Content__Filters">
            <SearchBar
              options={inputOptions}
              onOptionPress={onOptionPress}
              isComuneLoading={isComuneLoading}
            />
          </div>
        </div>
        <div>
          <Results />
        </div>
      </div>
    </div>
  );
};

import { Candidate } from '@/lib/model';
import * as React from 'react';
import './index.scss';

export const Coalition: React.FC<Candidate> = ({
  label,
  fullName,
  votes,
  percentage,
  fillColor,
}) => (
  <div className="Coalition">
    <div className="Coalition__title">
      <div>
        {fullName && (
          <span
            className={`Coalition__title__label ${
              fullName ? 'Coalition__title__label__fullName' : ''
            }`}
          >
            {fullName.replace(
              'LETIZIA MARIA BRICHETTO ARNABOLDI',
              'LETIZIA MORATTI'
            )}
          </span>
        )}
        {!fullName && (
          <span
            className={`Coalition__title__label ${
              fullName ? 'Coalition__title__label__fullName' : ''
            }`}
          >
            {' '}
            {label}
          </span>
        )}
        {fullName && (
          <span className="Coalition__title__fullName">{label}</span>
        )}
      </div>
    </div>
    <div className="Coalition__data">
      <div className="Coalition__bar">
        <div style={{ backgroundColor: fillColor, width: `${percentage}%` }} />
      </div>
      <div className="Coalition__values">
        <span>{votes === 'NaN' ? `0` : votes}</span>
        <span>{`${percentage}%`}</span>
      </div>
    </div>
  </div>
);

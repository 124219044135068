import { Coalition } from '@/components/Coalition';
import { CoalitionsHeader } from '@/components/CoalitionsHeader';
import { Coalition as CoalitionType } from '@/lib/model';
import * as React from 'react';
import './index.scss';

export interface CoalitionProps {
  data: CoalitionType[];
}

export const Coalitions: React.FC<CoalitionProps> = ({ data }) => (
  <div className="Coalitions">
    {data.length > 0 && <CoalitionsHeader />}
    {data.map((coalition, i) => (
      <React.Fragment key={i.toString()}>
        {i > 0 && (
          <div
            style={{ borderTop: '1px solid #000000', paddingTop: '1em' }}
          ></div>
        )}
        <Coalition {...coalition} />
        <div className="Coalitions__Candidates">
          {coalition.candidates.map((candidate, k) => (
            <Coalition {...candidate} key={`${i}_${k}`} />
          ))}
        </div>
      </React.Fragment>
    ))}
  </div>
);

export const parsePercentage = (result: string) => {
  if (!result) {
    return 0;
  }

  return parseFloat(`${result}`.replace(',', '.'));
};

export const formatNumber = (num: number) => {
  return new Intl.NumberFormat('it-IT', {}).format(num);
};

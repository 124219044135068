import Config from '@/lib/config';
import { dispatchTrackingEvent, useMainContext } from '@/lib/context';
import { formatNumber, parsePercentage } from '@/lib/helpers';
import { Result, SummaryRow } from '@/lib/model';
import * as React from 'react';
import { Categories } from './Categories';
import { Coalitions } from './Coalitions';
import './index.scss';
import { Live } from './Live';
import { Summary } from './Summary';

export const Results: React.FC = () => {
  const { mainResult, result, setResult, dynamicTurnout } = useMainContext();

  if (!mainResult) {
    return null;
  }

  const results = [mainResult, ...(result ? [result] : [])];
  const lastResult = [...results].pop() as Result;

  let sezioniCurrent = lastResult.scrutinized.current;

  let lastDynamicTurnoutCom = dynamicTurnout?.com_vot?.slice(-1)[0];
  let comunicazioneAttiva = 1; // default on first
  if (dynamicTurnout !== undefined && dynamicTurnout !== null) {
    if (
      dynamicTurnout.desc === 'ITALIA' &&
      dynamicTurnout.com !== undefined &&
      dynamicTurnout.com.length > 0
    ) {
      comunicazioneAttiva = dynamicTurnout.com[0].com_att || 1;
    } else if (dynamicTurnout.com_att !== undefined) {
      comunicazioneAttiva = parseInt(dynamicTurnout.com_att) || 1;
    }
    if (dynamicTurnout?.com_vot !== undefined) {
      lastDynamicTurnoutCom = dynamicTurnout?.com_vot[comunicazioneAttiva - 1];
      if (!Config.isLiveActive) {
        sezioniCurrent = lastDynamicTurnoutCom.enti_p;
      }
    }
  }

  const summary: SummaryRow[] = [
    {
      label: 'Affluenza',
      value: `${parsePercentage(lastDynamicTurnoutCom?.perc || '0').toFixed(
        2
      )}%`,
    },
    {
      label: 'Totale Elettori',
      value: `${formatNumber(dynamicTurnout?.ele_t || 0)}`,
    },
    {
      label: 'Totale Votanti',
      value: `${formatNumber(lastDynamicTurnoutCom?.vot_t || 0)}`,
    },
    {
      label: 'Schede Bianche',
      value: lastResult.summary.blankCards,
    },
    {
      label: 'Schede Contestate',
      value: lastResult.summary.contestedCards,
    },
    {
      label: 'Schede Nulle',
      value: lastResult.summary.nullCards,
    },
  ];

  const onCategoryClick = () => {
    setResult(null);
    dispatchTrackingEvent({});
  };

  return (
    <div className="Results">
      <Categories results={results} onClick={onCategoryClick} />
      {!lastResult.idCodes.secondBallot && result !== null && (
        <p>Sindaco eletto al primo turno</p>
      )}
      <Live
        currentNumber={sezioniCurrent}
        total={lastResult.scrutinized.total}
      />
      <>
        <Coalitions data={lastResult.coalitions} />
        <Summary data={summary} />
      </>
    </div>
  );
};

import { SummaryRow } from '@/lib/model';
import * as React from 'react';
import './index.scss';

export interface SummaryProps {
  data: SummaryRow[];
}

export const Summary: React.FC<SummaryProps> = ({ data }) => (
  <div className="Summary">
    {data.map((row, i) => {
      if (row.value === '-1' || row.value === 'NaN') {
        return null;
      }

      return (
        <div className="Summary__row" key={i.toString()}>
          <div>
            <span>{row.label}</span>
          </div>
          <span>{`${row.value}`}</span>
        </div>
      );
    })}
  </div>
);

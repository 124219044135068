import Config, { Clients } from '@/lib/config';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById(`root-${Config.projectName}`) as HTMLElement
);

class ElectionWidget extends React.Component {
  componentDidMount() {
    if (Config.client === Clients.SOLE) {
      // dispatch tracking event
      const event = new CustomEvent('electio:navigate', {
        detail: {},
      });

      document.dispatchEvent(event);
    }
  }

  render() {
    return (
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }
}

root.render(<ElectionWidget />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

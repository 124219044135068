import CandidatesColors from '@/data/candidatesColorsB.json';

export const getCandidateColor = (comune: string, o: any) => {
  let candColor = CandidatesColors.filter(
    c => c.comune === comune && c.cognome === o.cogn
  );
  if (candColor.length > 0) {
    return candColor[0].color;
  }
  return '#666';
};

export const getListaColor = (comune: string, o: any) => {
  let candColor = getCandidateColor(comune, o);
  if (candColor !== '#666') {
    return candColor + 'b0';
  }
  return '#CCC';
};

export const getFullNameCandidate = (o: any) => {
  if (o.cogn !== undefined && o.nome !== undefined) {
    return o.nome + ' ' + o.cogn;
  }
  return null;
};

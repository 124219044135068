import AdminDivisionsComune from '@/data/getlisteGB.json';
import {
  getCandidateColor,
  getFullNameCandidate,
  getListaColor,
} from '@/lib/candidatesHelpers';
import { formatNumber, parsePercentage } from '@/lib/helpers';
import {
  AutocompleteOption,
  DataLevel,
  Region,
  Result,
  ResultAPI,
  ResultCodes,
  Year,
} from '@/lib/model';
import Config from './config';

const API_URL = `https://2023-real-time-data.s3.eu-central-1.amazonaws.com/${Config.projectDataLocation}/`;

let fetchController = new AbortController();
let fetchDynamicTurnoutController = new AbortController();

export const parseResult = (result: ResultAPI, isSecondBallot: boolean) => {
  const year = Year.Y2023;
  const region = Region.LOMBARDIA;
  const level = DataLevel.COMUNE;

  if (isSecondBallot) {
    result.cand = result.cand.filter(c => c.pos !== null);
  }

  return {
    level,
    year,
    region,
    placeName: result.int.desc_com || 'Italia',
    showVoti: true,
    scrutinized: {
      current: result.int.sz_p_sind || 0,
      total: result.int.sz_tot || 0,
    },
    coalitions:
      result.cand
        ?.map(coalition => ({
          coalitionId: coalition.pos,
          label: '',
          fullName: getFullNameCandidate(coalition),
          votes: formatNumber(coalition.voti_uni || coalition.voti || 0),
          percentage: parsePercentage(
            coalition.perc_uni || coalition.perc || '0'
          ),
          fillColor: getCandidateColor(result?.int?.desc_com || '', coalition),
          candidates: coalition.liste
            ?.map(candidate => ({
              coalitionId: coalition.pos,
              candidateId: candidate.pos,
              label: candidate.descr_lista,
              fullName: getFullNameCandidate(candidate),
              votes: formatNumber(candidate.voti),
              percentage: parsePercentage(candidate.perc),
              fillColor: getListaColor(result?.int?.desc_com || '', coalition),
            }))
            .sort(({ percentage: a }, { percentage: b }) => b - a),
        }))
        .sort(({ percentage: a }, { percentage: b }) => b - a) || [],
    idCodes: {
      codReg: `${result.int.cod_reg}`,
      codCirc: `${result.int.cod_circ}`,
      codProv: `${result.int.cod_prov}`,
      codClPlu: `${result.int.cod_plu}`,
      codClUni: `${result.int.cod_cl_uni}`,
      codCom: `${result.int.cod_com}`,
      secondBallot: isSecondBallot,
    },
    summary: {
      blankCards: `${formatNumber(result.int.sk_bianche)}`,
      contestedCards: `${formatNumber(result.int.sk_contestate)}`,
      nullCards: `${formatNumber(result.int.sk_nulle)}`,
    },
  };
};

const fetchResults = async (
  abort: boolean,
  codes?: ResultCodes
): Promise<ResultAPI | ResultAPI[] | null> => {
  let url = `${API_URL}`;

  // in case we're working in 2nd ballot, we change previous url format to new one
  if (codes?.secondBallot) {
    url += 'ballottaggio/10';
  } else {
    // use normal formatting for previous elections
    if (!codes) {
      url += `votanti/`;
    }

    url += `08`;
  }

  if (codes) {
    url += `${codes?.codReg.padStart(2, '0')}`; // region
    url += `${codes?.codProv.padStart(3, '0')}`; // province
    url += `${codes?.codCom.padStart(4, '0')}`; // comune
  }

  url += '.json';

  try {
    if (abort) {
      fetchController.abort();
      fetchController = new AbortController();
    }

    let res: ResultAPI | ResultAPI[] = await (
      await fetch(url, {
        signal: fetchController.signal,
      })
    )
      .json()
      .catch(_ => {
        return null;
      });

    return res;
  } catch {
    return null;
  }
};

export const getDynamicTurnout = async (codes?: ResultCodes) => {
  try {
    fetchDynamicTurnoutController.abort();
    fetchDynamicTurnoutController = new AbortController();

    let url = `${API_URL}votanti/`;

    // in case we're working in 2nd ballot, we change previous url format to new one
    if (!codes || codes?.secondBallot) {
      url += 'ballottaggio/10';
    } else {
      url += `08`;
    }

    if (codes) {
      url += `${codes?.codReg.padStart(2, '0')}`; // region
      url += `${codes?.codProv.padStart(3, '0')}`; // province
      url += `${codes?.codCom.padStart(4, '0')}`; // comune
    }

    url += '.json';

    const dynamicTurnout = await (
      await fetch(url, {
        signal: fetchDynamicTurnoutController.signal,
      })
    ).json();

    // if no codes are provided we're talking about the national result
    if (!codes) {
      return dynamicTurnout.enti.ente_p;
    }

    // otherwise we return the 'comune' turnout
    return dynamicTurnout;
  } catch (e) {
    return [];
  }
};

export const getResults = async (
  abort: boolean,
  codes?: ResultCodes
): Promise<Result[]> => {
  try {
    let data = await fetchResults(abort, codes);
    data = !data ? [] : Array.isArray(data) ? data : [data];

    // quick trick for getting affluenza in italian level
    if (!codes) {
      const elenchiForNationalResult = await (
        await fetch(`${API_URL}ballottaggio/elenchi.json`)
      ).json();

      let sind = 0;
      let tot = 0;

      elenchiForNationalResult.enti.forEach((el: any) => {
        sind += el.sz_sind;
        tot += el.sz_tot;
      });

      data[0].int.sz_tot = tot;
      data[0].int.sz_p_sind = sind;
    }

    const isSecondBallot = !!codes?.secondBallot;

    return data.map((result: ResultAPI) => parseResult(result, isSecondBallot));
  } catch (e) {
    console.log('error fetching results', e);

    return [];
  }
};

export const getAutocompleteOptions = () => {
  const data = AdminDivisionsComune.reg
    .reduce(
      (accRegions: AutocompleteOption[], currentRegion) => [
        ...accRegions,
        ...currentRegion.prov.reduce(
          (accProvinces: AutocompleteOption[], currentProvince) => [
            ...accProvinces,
            ...currentProvince.com.map(currentComune => ({
              nameComune: currentComune.desc_com,
              nameProvincia: currentProvince.desc_prov,
              nameRegione: currentRegion.desc_reg,
              codCom: `${currentComune.cod_com}`,
              codProv: `${currentProvince.cod_prov}`,
              codReg: `${currentRegion.cod_reg}`,
              secondBallot: currentComune.b,
            })),
          ],
          []
        ),
      ],
      []
    )
    .sort((a, b) => {
      return a.nameProvincia.localeCompare(b.nameProvincia);
    });

  return data;
};

export const getLastVersion = async () => {
  return await (await fetch(`${API_URL}last_version.json`)).json();
};

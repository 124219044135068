import Config from '@/lib/config';
import { formatNumber } from '@/lib/helpers';
import * as React from 'react';
import './index.scss';

export interface LiveProps {
  currentNumber: number;
  total: number;
}

export const Live: React.FC<LiveProps> = ({ currentNumber, total }) => (
  <div className="Live">
    <div className="Live__header">
      <span>
        Sezioni {Config.isLiveActive ? 'scrutinate' : 'pervenute'}:
        <br />
        <b>{formatNumber(currentNumber)}</b> su <b>{formatNumber(total)}</b>
      </span>
      {Config.isLiveActive && (
        <div className="Live__header__button">
          <img
            src={`${Config.publicUrl}/assets/liveIcon.svg`}
            alt="Live Results"
          />
          <span>Live</span>
        </div>
      )}
    </div>
    <div className="Live__progress">
      <div
        className="Live__progress__bar"
        style={{
          width: `${(currentNumber / total) * 100}%`,
        }}
      ></div>
    </div>
  </div>
);
